import React, { useRef, useState, useEffect } from 'react'
/** @jsxImportSource @emotion/react */
import {
  Grid,
  Button,
  Form,
  Dropdown,
  Spinner,
  Layout,
  Input,
  useToaster,
} from '@enterprise-ui/canvas-ui-react'
import '../Custom.css'
import { useFormik, validateYupSchema, yupToFormErrors } from 'formik'
import {
  useQuery,
  useMutation,
  useLazyQuery,
  useApolloClient,
} from '@apollo/client'
import {
  UpdateCaseRequest,
  GET_CASE,
  GET_USERPROFILES,
  UPDATE_CASE,
  UpdateCaseVar,
  CaseResponse,
  GET_VENDOR,
  GET_CASE_ATTACHMENTS,
  CaseAttachments,
  CaseAttachmentVariable,
  Case,
  CaseVariable,
  NoteInput,
  AuditEntryInput,
  GetUserProfiles,
  VendorResponse,
  VendorRequest,
  GET_DOCPREFIXMAPS,
  DocPrefixMapsResponse,
  FILE_CLAIM,
  FileClaimVar,
} from '../Common/Queries'
import { useProfile } from '../../Layout/UserProfile/UserProfileProvider'
import EditDisputeDetails from '../Common/EditDisputeDetails'
import EditVendorDetails from '../Common/EditVendorDetails'
import EditDocumentDetails from '../Common/EditDocumentDetails'
import EditPaymentDetails from '../Common/EditPaymentDetails'
import EditRecieptDetails from '../Common/EditRecieptDetails'
import EditWorkflowDetails from '../Common/EditWorkflowDetails'
import EditResolutionDetails from '../Common/EditResolutionDetails'
import EditStatusModal from '../Common/EditStatusModal'
import EditResolutionModal from '../Common/EditResolutionModal'
import EditDescriptionModal from '../Common/EditDescriptionModal'
import { findResolutionCodeValues } from '../Services/ResolutionMapping'
import { CaseStatus, CaseType, FieldsMapping } from '../Services/ConstantData'
import AttachmentDetails from '../Common/AttachmentDetails'
import DownloadAttachments from '../Common/DownloadAttachments'
import useAttachment from '../Hooks/useAttachment'
import { useAuth } from '@praxis/component-auth'
import NotesTab from '../Common/NotesTab'
import moment from 'moment'
import {
  DATE_FORMAT,
  RESET,
  SET_FIELD,
  WORKFLOW_SUMMARY_HEADING,
  WORKFLOW,
  STATUS,
  RESOLVE,
  DOCREF,
  CRVENDOR,
  DESCR,
  ATTACHMENTS,
  COMMENTS,
  EDIT_INTERNALPROCESS_HEADING,
  REOPEN,
  DOCCATEGORY,
} from '../Common/Constants'
import {
  BottomBorderDiv,
  editButtons,
  editButtonsGroup,
} from '../Common/SynergyDivComponent'
import EditReferenceDetails from '../Claim/ClaimEdit/EditReferenceDetails'
import EditCarrierVendorDetails from '../Claim/ClaimEdit/EditCarrierVendorDetails'
import EditCarrierVendorModal from '../Claim/ClaimEdit/EditCarrierVendorModal'
import EditReferenceModal from '../Claim/ClaimEdit/EditReferenceModal'
import CaseDateSummary from '../Common/CaseDateSummary'
import { useGlobalForm } from '../Context/GlobalFormStateContext'
import BreadCrumb from '../Common/BreadCrumb'
import { activityData } from '../Services/saveActivity'
import SpinnerLoad from '../Common/SpinnerLoad'
import DuplicateCaseSummary from '../Common/DuplicateCaseSummary'
import { findDuplicates } from '../Services/DuplicateCases'
import ReopenModal from '../Common/ReopenModal'
import ReopenDetails from '../Common/ReopenDetails'
import {
  findDocumentTypeIdValues,
  findDocumentSubTypeValues,
} from '../Services/DocumentPrefixMapping'
import EditDocumentCategoryModal from '../Common/EditDocumentCategoryModal'
import * as Yup from 'yup'
import SupportingAlert from '../Common/SupportingAlert'
import { CaretDownIcon, EnterpriseIcon } from '@enterprise-ui/icons'
import { findClaimFields } from '../Services/ClaimDisplayFields'
import EditClaimReceipts from '../Claim/ClaimEdit/EditClaimReceipts'
import EditClaimItemLevelDetails from '../Claim/ClaimEdit/EditClaimItemLevelDetails'

interface Props {
  id: string
  duplicatesCases?: any
}

const TMClaimView: React.FC<Props> = (props) => {
  const {
    userProfile,
    hasAnyAdminRole,
    isTransportationTeamRole,
    isImportoUserRole,
  } = useProfile()
  const myDivToFocus = useRef<any>(null)
  const showActivity = useRef<any>(null)
  const [assignedUserValues, setAssignedUserValues] = useState<any>([])
  const [resolutionMappingValues, setresolutionMappingValues] = useState<any>(
    []
  )
  const [filteredDocTypeValues, setFilteredDocTypeValues] = useState<any>([])
  const [filteredDocSubTypeValues, setFilteredDocSubTypeValues] = useState<any>(
    []
  )
  const [caseUpdate, setCaseUpdate] = useState<string>('')
  const [state, dispatch] = useGlobalForm()
  const { session } = useAuth()
  const client = useApolloClient()
  const makeToast = useToaster()
  const userName = session?.userInfo?.fullName ?? ''
  const claimTypesToSubmitToImporto = ['DCR', 'DAM', 'EXR', 'SHT', 'SOS']
  const schema = Yup.object().shape<UpdateCaseRequest>({
    resolutionCode: Yup.number().test(
      'Fine',
      'Enter Valid Resolution Explanation',
      function (value) {
        if (
          (value === undefined || value === null) &&
          state.resCodeCheck &&
          state.resolutionFlag
        ) {
          return false
        }
        return true
      }
    ),
    resolutionAmount: Yup.number().test(
      'Fine',
      'Resolution Amount cannot be greater than the Disputed Amount',
      function (value) {
        const context = this.options.context as any
        if (
          value &&
          value > context?.disputedDocumentAmount &&
          state.resolutionFlag
        ) {
          return false
        }
        return true
      }
    ),
  })

  const { data: mappingValues } = useQuery<DocPrefixMapsResponse>(
    GET_DOCPREFIXMAPS,
    {}
  )
  const { data: casedetails, refetch: caseRefetch } = useQuery<
    Case,
    CaseVariable
  >(GET_CASE, {
    variables: {
      id: parseInt(props.id),
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data: Case) => {
      const caseData = data?.getCase
      const claimCaseData = data?.getCase?.claimDetails
      if (caseData) {
        formik.setValues(caseData)
        if (claimCaseData?.carrierVendorId === null) {
          formik.setFieldValue('claimDetails.carrierVendorId', '')
        }
        if (
          caseData?.resolutionCode === null ||
          caseData?.resolutionCode === 0
        ) {
          formik.setFieldValue('resolutionCode', undefined)
        }
        if (claimCaseData?.carrierVendorContactEmail === null) {
          formik.setFieldValue('claimDetails.carrierVendorContactEmail', '')
        }
        if (
          caseData?.resolutionAmount === null ||
          caseData?.resolutionAmount === 0
        ) {
          formik.setFieldValue('resolutionAmount', undefined)
        }

        if (
          claimCaseData?.referenceDocumentCreateDate !== null &&
          claimCaseData?.referenceDocumentCreateDate !== undefined
        ) {
          formik.setFieldValue(
            'claimDetails.referenceDocumentCreateDate',
            moment(claimCaseData?.referenceDocumentCreateDate).format(
              'YYYY-MM-DD'
            )
          )
        }
        if (
          claimCaseData?.shipDate !== null &&
          claimCaseData?.shipDate !== undefined
        ) {
          formik.setFieldValue(
            'claimDetails.shipDate',
            moment(claimCaseData?.shipDate).format('YYYY-MM-DD')
          )
        }
        if (claimCaseData?.cbdcDocumentDate) {
          formik.setFieldValue(
            'claimDetails.cbdcDocumentDate',
            moment(claimCaseData?.cbdcDocumentDate).format('YYYY-MM-DD')
          )
        }
        if (claimCaseData?.retailAmount) {
          formik.setFieldValue(
            'claimDetails.retailAmount',
            claimCaseData?.retailAmount
          )
        }
        if (claimCaseData?.inventoryRemovalId) {
          formik.setFieldValue(
            'claimDetails.inventoryRemovalId',
            claimCaseData?.inventoryRemovalId
          )
        }
        if (caseData?.notes && caseData?.notes.length !== 0) {
          formik.setFieldValue(
            'notes',
            caseData?.notes?.map(function (notes: NoteInput) {
              return {
                userId: notes.userId,
                userName: notes.userName,
                commentBody: notes.commentBody,
                isInternal: notes.isInternal,
                commentDate: notes.commentDate,
              }
            })
          )
        }
        if (caseData?.auditEntries !== null) {
          formik.setFieldValue('auditEntries', caseData?.auditEntries)
        }
        if (
          claimCaseData?.referenceDocumentNumber !== null &&
          claimCaseData?.referenceDocumentNumber !== undefined
        ) {
          formik.setFieldValue(
            'claimDetails.referenceDocumentNumber',
            claimCaseData?.referenceDocumentNumber
          )
        }
        dispatch({
          type: SET_FIELD,
          payload: [
            {
              id: 'requestType',
              value: caseData?.caseType,
            },
            {
              id: 'resAmount',
              value: caseData?.resolutionAmount
                ? caseData?.resolutionAmount
                : undefined,
            },
            {
              id: 'savedAttachments',
              value: dataattachments?.getCaseAttachments?.map(function (
                attachmentData: any
              ) {
                return {
                  fileName: attachmentData.fileName,
                  documentType: attachmentData.documentType,
                  documentNumber: attachmentData.documentNumber,
                  attachmentId: attachmentData.attachmentId,
                  createdBy: attachmentData.createdBy,
                  createdTimestamp: moment(
                    attachmentData?.createdTimestamp
                  ).format('MM/DD/YYYY'),
                }
              }),
            },
          ],
        })
      }
    },
    fetchPolicy: 'network-only',
  })
  const duplicateCases = findDuplicates(props.duplicatesCases, props.id)
  const claimData = casedetails?.getCase

  const { data: dataattachments, refetch: refetchAttachments } = useQuery<
    CaseAttachments,
    CaseAttachmentVariable
  >(GET_CASE_ATTACHMENTS, {
    variables: {
      caseId: props.id,
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    dispatch({
      type: SET_FIELD,
      payload: [
        {
          id: 'savedAttachments',
          value: dataattachments?.getCaseAttachments?.map(function (
            attachmentData: any
          ) {
            return {
              fileName: attachmentData.fileName,
              documentType: attachmentData.documentType,
              documentNumber: attachmentData.documentNumber,
              attachmentId: attachmentData.attachmentId,
              createdBy: attachmentData.createdBy,
              createdTimestamp: moment(attachmentData?.createdTimestamp).format(
                'YYYY-MM-DD'
              ),
            }
          }),
        },
      ],
    })
  }, [dataattachments, dispatch])

  useQuery<GetUserProfiles>(GET_USERPROFILES, {
    onCompleted: (datauser: GetUserProfiles) => {
      const values = datauser?.getUserProfiles?.map((userProfile: any) => ({
        id: userProfile.userId,
        value: userProfile.userId,
        label: userProfile.fullName,
        vendor: userProfile.isVendor,
      }))
      const userValues = values.filter(function (userValue: any) {
        return !userValue.vendor
      })
      setAssignedUserValues(userValues)
    },
    fetchPolicy: 'network-only',
  })

  const [updateCaseDispute, { loading: updateLoading }] = useMutation<
    CaseResponse,
    UpdateCaseVar
  >(UPDATE_CASE, {
    onCompleted: () => {
      dispatch({
        type: RESET,
      })
      refetchAttachments()
      makeToast({
        type: 'success',
        heading: caseUpdate,
        message: 'Success',
      })
    },
  })
  const claimType = findClaimFields(FieldsMapping, 'C', claimData)

  const formik = useFormik<UpdateCaseRequest>({
    initialValues: {},
    validationSchema: schema,
    validateOnChange: false,
    validateOnBlur: false,
    validate: (values) => {
      const validationSchema = schema
      try {
        validateYupSchema<UpdateCaseRequest>(
          values,
          validationSchema,
          true,
          values
        )
      } catch (err) {
        return yupToFormErrors(err)
      }
      return {}
    },
    onSubmit: async (values) => {
      const postDispute = {
        reasonCode: claimData?.reasonCode,
        reasonCodeText: claimData?.reasonCodeText,
        caseDescription: values?.caseDescription
          ? values?.caseDescription
          : claimData?.caseDescription,
        documentTypeId: Number(values?.documentTypeId)
          ? Number(values?.documentTypeId)
          : Number(claimData?.documentTypeId),
        documentTypeText: values?.documentTypeText
          ? values?.documentTypeText
          : claimData?.documentTypeText,
        documentSubTypeId: Number(values?.documentSubTypeId)
          ? Number(values?.documentSubTypeId)
          : Number(claimData?.documentSubTypeId),
        documentSubTypeText: values?.documentSubTypeText
          ? values?.documentSubTypeText
          : claimData?.documentSubTypeText,
        status: values?.status ? values?.status : claimData?.status,
        workflow:
          values?.workflow !== undefined
            ? values?.workflow
            : claimData?.workflow,
        lanId:
          values?.lanId !== undefined
            ? values?.lanId
            : claimData?.userProfile?.userId,
        departmentId: claimData?.departmentId,
        purchaseOrderNumber: claimData?.purchaseOrderNumber,
        locationId: claimData?.locationId,
        receiptId: claimData?.receiptId,
        receipts: claimData?.receipts,
        classId: claimData?.classId,
        resolutionCode: Number(values?.resolutionCode),
        resolutionCodeText:
          values?.resolutionCodeText !== undefined
            ? values?.resolutionCodeText
            : claimData?.resolutionCodeText,
        resolutionAmount:
          values?.resolutionAmount !== undefined
            ? values?.resolutionAmount
            : claimData?.resolutionAmount,
        resolutionText:
          values?.resolutionText !== undefined
            ? values?.resolutionText
            : claimData?.resolutionText,
        resolutionDate:
          values?.resolutionDate !== undefined
            ? values?.resolutionDate
            : claimData?.resolutionDate,
        disputedDocumentAmount: claimData?.disputedDocumentAmount,
        isReopen: values?.isReopen ? values?.isReopen : claimData?.isReopen,
        disputeDetails: {
          newDocumentNumber:
            values?.disputeDetails?.newDocumentNumber !== undefined
              ? values?.disputeDetails?.newDocumentNumber
              : claimData?.disputeDetails?.newDocumentNumber,
          newDocumentAmount: values?.disputeDetails?.newDocumentAmount
            ? values?.disputeDetails?.newDocumentAmount
            : claimData?.disputeDetails?.newDocumentAmount,
          newCheckNumber: values?.disputeDetails?.newCheckNumber
            ? values?.disputeDetails?.newCheckNumber
            : claimData?.disputeDetails?.newCheckNumber,
        },
        claimDetails: {
          shipDate:
            values?.claimDetails?.shipDate !== undefined &&
            values?.claimDetails?.shipDate !== null
              ? `${values.claimDetails?.shipDate}${DATE_FORMAT}`
              : claimData?.claimDetails?.shipDate
              ? moment(claimData?.claimDetails?.shipDate).format('YYYY-MM-DD')
              : claimData?.claimDetails?.shipDate,
          carrierVendorId: values.claimDetails?.carrierVendorId
            ? values.claimDetails?.carrierVendorId
            : claimData?.claimDetails?.carrierVendorId,
          carrierVendorName: values.claimDetails?.carrierVendorName
            ? values.claimDetails?.carrierVendorName
            : claimData?.claimDetails?.carrierVendorName,
          carrierVendorContact: values.claimDetails?.carrierVendorContact
            ? values.claimDetails?.carrierVendorContact
            : claimData?.claimDetails?.carrierVendorContact,
          carrierVendorContactEmail: values.claimDetails
            ?.carrierVendorContactEmail
            ? values.claimDetails?.carrierVendorContactEmail
            : claimData?.claimDetails?.carrierVendorContactEmail,
          carrierScacCode: values.claimDetails?.carrierScacCode
            ? values.claimDetails?.carrierScacCode
            : claimData?.claimDetails?.carrierScacCode,
          isMappedVendor: values.claimDetails?.isMappedVendor
            ? values.claimDetails?.isMappedVendor
            : claimData?.claimDetails?.isMappedVendor,
          referenceDocumentNumber:
            values?.claimDetails?.referenceDocumentNumber !== undefined
              ? values?.claimDetails?.referenceDocumentNumber
              : claimData?.claimDetails?.referenceDocumentNumber,
          referenceDocumentCreateDate:
            values?.claimDetails?.referenceDocumentCreateDate === undefined ||
            values?.claimDetails?.referenceDocumentCreateDate === null
              ? values?.claimDetails?.referenceDocumentCreateDate
              : `${moment(
                  values.claimDetails?.referenceDocumentCreateDate
                ).format('YYYY-MM-DD')}${DATE_FORMAT}`,
          cbdcDocumentDate:
            values?.claimDetails?.cbdcDocumentDate ??
            claimData?.claimDetails?.cbdcDocumentDate,
          inventoryRemovalId:
            values.claimDetails?.inventoryRemovalId ??
            claimData?.claimDetails?.inventoryRemovalId,
          retailAmount:
            values.claimDetails?.retailAmount ??
            claimData?.claimDetails?.retailAmount,
          invoiceDate:
            values?.claimDetails?.invoiceDate ??
            claimData?.claimDetails?.invoiceDate,
          receiptDate:
            values?.claimDetails?.receiptDate ??
            claimData?.claimDetails?.receiptDate,
          cartonsBilled:
            values?.claimDetails?.cartonsBilled ??
            claimData?.claimDetails?.cartonsBilled,
          cartonsReceived:
            values?.claimDetails?.cartonsReceived ??
            claimData?.claimDetails?.cartonsReceived,
          shortageCartons:
            values?.claimDetails?.shortageCartons ??
            claimData?.claimDetails?.shortageCartons,
          shortageAmount:
            values?.claimDetails?.shortageAmount ??
            claimData?.claimDetails?.shortageAmount,
          trailerNumber:
            values?.claimDetails?.trailerNumber ??
            claimData?.claimDetails?.trailerNumber,
          trackingNumber:
            values?.claimDetails?.trackingNumber ??
            claimData?.claimDetails?.trackingNumber,
          trailerType:
            values?.claimDetails?.trailerType ??
            claimData?.claimDetails?.trailerType,
          claimType:
            values?.claimDetails?.claimType ??
            claimData?.claimDetails?.claimType,
          itemLevelShortage:
            values?.claimDetails?.itemLevelShortage ??
            claimData?.claimDetails?.itemLevelShortage,
          claimReceipts:
            values?.claimDetails?.claimReceipts ??
            claimData?.claimDetails?.claimReceipts,
          documentNotification:
            values?.claimDetails?.documentNotification ??
            claimData?.claimDetails?.documentNotification,
        },
        reopenDetails: {
          reopenReasonId: values?.reopenDetails?.reopenReasonId
            ? values?.reopenDetails?.reopenReasonId
            : claimData?.reopenDetails?.reopenReasonId,
          reopenReason: values?.reopenDetails?.reopenReason
            ? values?.reopenDetails?.reopenReason
            : claimData?.reopenDetails?.reopenReason,
          reopenDate: values?.reopenDetails?.reopenDate
            ? values?.reopenDetails?.reopenDate
            : claimData?.reopenDetails?.reopenDate,
          reopenUser: values?.reopenDetails?.reopenUser
            ? values?.reopenDetails?.reopenUser
            : claimData?.reopenDetails?.reopenUser,
          previousResolutionDate: values?.reopenDetails?.previousResolutionDate
            ? values?.reopenDetails?.previousResolutionDate
            : claimData?.reopenDetails?.previousResolutionDate,
          previousResolutionUser: values?.reopenDetails?.previousResolutionUser
            ? values?.reopenDetails?.previousResolutionUser
            : claimData?.reopenDetails?.previousResolutionUser,
          previousResolutionCode: values?.reopenDetails?.previousResolutionCode
            ? values?.reopenDetails?.previousResolutionCode
            : claimData?.reopenDetails?.previousResolutionCode,
          previousResolutionCodeText: values?.reopenDetails
            ?.previousResolutionCodeText
            ? values?.reopenDetails?.previousResolutionCodeText
            : claimData?.reopenDetails?.previousResolutionCodeText,
          previousResolutionText: values?.reopenDetails?.previousResolutionText
            ? values?.reopenDetails?.previousResolutionText
            : claimData?.reopenDetails?.previousResolutionText,
          previousResolutionAmount: values?.reopenDetails
            ?.previousResolutionAmount
            ? values?.reopenDetails?.previousResolutionAmount
            : claimData?.reopenDetails?.previousResolutionAmount,
          previousResolutionDocumentNumber: values?.reopenDetails
            ?.previousResolutionDocumentNumber
            ? values?.reopenDetails?.previousResolutionDocumentNumber
            : claimData?.reopenDetails?.previousResolutionDocumentNumber,
        },
        auditEntries:
          values?.auditEntries?.length !== 0
            ? values?.auditEntries
            : claimData?.auditEntries?.map(function (
                auditEntries: AuditEntryInput
              ) {
                return {
                  activityDescription: auditEntries.activityDescription,
                  userId: auditEntries.userId,
                  workflow: auditEntries.workflow,
                  status: auditEntries.status,
                  assignUserId: auditEntries.assignUserId,
                  activityTimestamp: auditEntries.activityTimestamp,
                }
              }),
        notes: values?.notes,
        agingDate: claimData?.agingDate,
      }
      await updateCaseDispute({
        variables: {
          id: Number(props.id),
          input: postDispute,
        },
      }).then(() => {
        caseRefetch()
      })
    },
  })

  const onReopenChange = (e: any) => {
    setCaseUpdate(REOPEN)
    dispatch({
      type: SET_FIELD,
      payload: [{ id: 'reOpenModalFlag', value: true }],
    })
    formik.setFieldValue('workflow', claimData?.workflow)
    formik.setFieldValue('lanId', claimData?.userProfile?.userId)
  }

  const { uploadAttachment, attachmentDetails } = useAttachment()
  const prepareToUploadAttachments = async () => {
    setCaseUpdate(ATTACHMENTS)
    const activityDescription = 'Uploaded Attachment'
    const adAuditEntry = activityData(
      userName,
      activityDescription,
      formik.values
    )
    formik.setFieldValue('auditEntries', adAuditEntry)
    await uploadAttachment(props.id, claimData?.vendorNumber).then(() =>
      formik.handleSubmit()
    )
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [onCrVendorChange] = useLazyQuery<VendorResponse, VendorRequest>(
    GET_VENDOR,
    {
      variables: {
        id: Number(formik.values.claimDetails?.carrierVendorId),
      },
      onCompleted: (vendor: VendorResponse) => {
        const getCrVendorDetails = vendor?.getVendor
        if (state.cvendorIdSearch) {
          if (getCrVendorDetails.vendorTypeCode === 'T') {
            formik.setFieldValue(
              'claimDetails.carrierVendorName',
              getCrVendorDetails?.vendorName
            )
            formik.setFieldError('claimDetails.carrierVendorId', undefined)
            dispatch({
              type: SET_FIELD,
              payload: [{ id: 'isVendorNextFlag', value: true }],
            })
          } else {
            formik.setFieldError(
              'claimDetails.carrierVendorId',
              'Not a Valid Transport/Carrier Vendor!'
            )
            dispatch({
              type: SET_FIELD,
              payload: [
                { id: 'isVendorNextFlag', value: false },
                { id: 'cvendorIdSearch', value: false },
              ],
            })
          }
        }
      },
      onError: (_error: any) => {
        if (state.cvendorIdSearch) {
          dispatch({
            type: SET_FIELD,
            payload: [{ id: 'cvendorIdSearch', value: false }],
          })
          formik.setFieldError(
            'claimDetails.carrierVendorId',
            'Not a Valid Vendor, Please try again'
          )
          dispatch({
            type: SET_FIELD,
            payload: [{ id: 'isVendorNextFlag', value: false }],
          })
        }
      },
      fetchPolicy: 'network-only',
    }
  )

  const getCrVendor = async () => {
    try {
      const { data } = await client.query<VendorResponse, VendorRequest>({
        query: GET_VENDOR,
        variables: {
          id: Number(formik.values.claimDetails?.carrierVendorId),
        },
        fetchPolicy: 'network-only',
      })
      const getCrVendorDetails = data?.getVendor
      if (getCrVendorDetails !== null) {
        if (getCrVendorDetails.vendorTypeCode === 'T') {
          formik.setFieldValue(
            'claimDetails.carrierVendorName',
            getCrVendorDetails?.vendorName
          )
          formik.setFieldError('claimDetails.carrierVendorId', undefined)
          dispatch({
            type: SET_FIELD,
            payload: [{ id: 'isVendorNextFlag', value: true }],
          })
        } else {
          formik.setFieldError(
            'claimDetails.carrierVendorId',
            'Not a Valid Transport/Carrier Vendor!'
          )
          dispatch({
            type: SET_FIELD,
            payload: [{ id: 'isVendorNextFlag', value: false }],
          })
        }
      } else {
        console.log('No Data Found')
      }
    } catch (errors: any) {
      formik.setFieldError(
        'claimDetails.carrierVendorId',
        'Not a Valid Vendor, Please try again'
      )
      dispatch({
        type: SET_FIELD,
        payload: [{ id: 'isVendorNextFlag', value: false }],
      })
      console.log('Error', errors?.message)
    }
  }

  const onAttachmentFocus = () => {
    if (myDivToFocus.current) {
      myDivToFocus.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
      myDivToFocus.current.focus()
    }
  }

  const showActivityCard = () => {
    if (showActivity.current) {
      showActivity.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      })
      showActivity.current.focus()
    }
  }

  const onAssignUser = () => {
    dispatch({
      type: SET_FIELD,
      payload: [
        { id: 'userFlag', value: true },
        { id: 'assignLanId', value: claimData?.userProfile?.userId },
        { id: 'assignWorkflow', value: claimData?.workflow },
      ],
    })
    setCaseUpdate(WORKFLOW)
    formik.setFieldValue('lanId', claimData?.userProfile?.userId)
  }

  const onStatusProgressChange = (e: any) => {
    setCaseUpdate(STATUS)
    formik.setFieldValue('status', CaseStatus.INPROGRESS)
    const activityDescription = `${'Changed the Case Status to '}${
      CaseStatus.INPROGRESS
    }`
    const adAuditEntry = activityData(
      userName,
      activityDescription,
      formik.values
    )
    formik.setFieldValue('auditEntries', adAuditEntry)
    formik.handleSubmit(e)
  }

  const onStatusAwaitChange = () => {
    setCaseUpdate(STATUS)
    formik.setFieldValue('status', CaseStatus.AWAITINGINFO)
    const activityDescription = `${'Changed the Case Status to '}${
      CaseStatus.AWAITINGINFO
    }`
    const adAuditEntry = activityData(
      userName,
      activityDescription,
      formik.values
    )
    formik.setFieldValue('auditEntries', adAuditEntry)
    dispatch({
      type: SET_FIELD,
      payload: [{ id: 'changeAwaitingInfo', value: false }],
    })
    formik.handleSubmit()
  }
  const onStatusResolveChange = (e: any) => {
    setCaseUpdate(RESOLVE)
    setresolutionMappingValues(
      findResolutionCodeValues(mappingValues?.getResolutionCodesMap, claimData)
    )
    dispatch({
      type: SET_FIELD,
      payload: [
        { id: 'resolutionFlag', value: true },
        { id: 'resCodeCheck', value: true },
      ],
    })
  }

  const onDescriptionChange = (e: any) => {
    setCaseUpdate(DESCR)
    dispatch({
      type: SET_FIELD,
      payload: [{ id: 'caseDescriptionFlag', value: true }],
    })
  }

  const onDocRefDetailsChange = (e: any) => {
    setCaseUpdate(DOCREF)
    dispatch({
      type: SET_FIELD,
      payload: [{ id: 'docReferenceFlag', value: true }],
    })
  }

  const onCarrVendDetailsChange = (e: any) => {
    setCaseUpdate(CRVENDOR)
    dispatch({
      type: SET_FIELD,
      payload: [{ id: 'carrVendorDetailsFlag', value: true }],
    })
  }

  const handleCrVendorSearch = () => {
    dispatch({
      type: SET_FIELD,
      payload: [{ id: 'cvendorIdSearch', value: true }],
    })
    getCrVendor()
    // onCrVendorChange()
  }

  const handleSave = () => {
    onCrVendorChange()
    if (state.isVendorNextFlag) {
      dispatch({
        type: SET_FIELD,
        payload: [{ id: 'carrVendorDetailsFlag', value: false }],
      })
      formik.handleSubmit()
    }
  }

  const onDocumentCategoryChange = (e: any) => {
    setCaseUpdate(DOCCATEGORY)
    dispatch({
      type: SET_FIELD,
      payload: [{ id: 'documentCategoryFlag', value: true }],
    })
    if (claimData?.documentTypeId) {
      formik.setFieldValue('documentTypeId', Number(claimData?.documentTypeId))
    }
    if (claimData?.documentSubTypeId) {
      formik.setFieldValue(
        'documentSubTypeId',
        Number(claimData?.documentSubTypeId)
      )
    }
    formik.setFieldValue('workflow', claimData?.workflow)
    formik.setFieldValue('lanId', claimData?.userProfile?.userId)
    formik.setFieldValue('status', claimData?.status)
  }

  const [onClaimFile, { loading: submitLoading }] = useMutation<
    CaseResponse,
    FileClaimVar
  >(FILE_CLAIM, {
    variables: {
      id: parseInt(props.id),
    },
    onCompleted: () => {
      makeToast({
        type: 'success',
        heading: 'Claim Created',
        message: 'Success',
      })
      caseRefetch()
    },
  })

  useEffect(() => {
    if (claimData?.caseSubType && mappingValues?.getDocumentTypeMaps) {
      setFilteredDocTypeValues(
        findDocumentTypeIdValues(mappingValues?.getDocumentTypeMaps, claimData)
      )
      if (claimData?.caseSubType && formik.values.documentTypeId) {
        setFilteredDocSubTypeValues(
          findDocumentSubTypeValues(
            mappingValues?.getDocumentTypeMaps,
            claimData,
            formik.values.documentTypeId
          )
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    claimData?.caseSubType,
    formik.values.documentTypeId,
    mappingValues?.getDocumentTypeMaps,
  ])

  useEffect(() => {
    if (state?.comments) {
      setCaseUpdate(COMMENTS)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.comments])

  useEffect(() => {
    if (state?.changeAwaitingInfo) {
      onStatusAwaitChange()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.changeAwaitingInfo])

  const fileClaim = () => {
    onClaimFile()
  }
  return (
    <Layout.Body includeRail className="hc-pa-md">
      <Grid.Container>
        <BreadCrumb caseId={props.id} />
        <Grid.Item xs={12} className="hc-pb-none hc-mb-none">
          {claimData?.status !== CaseStatus.CLOSED && (
            <Button
              type="primary"
              css={editButtons}
              onClick={onAttachmentFocus}
            >
              Attach Files
            </Button>
          )}
          <Button
            type="primary"
            css={editButtonsGroup}
            onClick={showActivityCard}
          >
            Activity
          </Button>

          <Dropdown size="dense" location="bottom-left">
            <Button type="primary" css={editButtons}>
              More
              <EnterpriseIcon icon={CaretDownIcon}></EnterpriseIcon>
            </Button>
            <Dropdown.Menu>
              <Dropdown.MenuItem
                onClick={(e: any) => {
                  onDescriptionChange(e)
                }}
                disabled={
                  claimData?.status === CaseStatus.RESOLVED ||
                  claimData?.status === CaseStatus.CLOSED
                }
              >
                Case Brief Explanation
              </Dropdown.MenuItem>
              <Dropdown.MenuItem
                onClick={(e: any) => {
                  onDocRefDetailsChange(e)
                }}
              >
                Claim Document Details
              </Dropdown.MenuItem>
              <Dropdown.MenuItem
                onClick={(e: any) => {
                  onCarrVendDetailsChange(e)
                }}
                disabled={
                  claimData?.status === CaseStatus.RESOLVED ||
                  claimData?.status === CaseStatus.CLOSED
                }
              >
                Update Carrier Vendor
              </Dropdown.MenuItem>
              <Dropdown.MenuItem
                onClick={(e: any) => {
                  onDocumentCategoryChange(e)
                }}
              >
                Document Categories
              </Dropdown.MenuItem>
            </Dropdown.Menu>
          </Dropdown>
          {claimData?.status !== CaseStatus.RESOLVED &&
            claimData?.status !== CaseStatus.CLOSED && (
              <Button
                type="primary"
                css={editButtonsGroup}
                onClick={onAssignUser}
              >
                Assign
              </Button>
            )}
          {(claimData?.status === CaseStatus.AWAITINGINFO ||
            (claimData?.userProfile?.userId &&
              (claimData?.status === CaseStatus.NEW ||
                claimData?.status === CaseStatus.REOPEN))) && (
            <Button
              type="primary"
              css={editButtons}
              onClick={(e: any) => {
                onStatusProgressChange(e)
              }}
            >
              {'set to InProgress'}
            </Button>
          )}
          {claimData?.status === CaseStatus.INPROGRESS && (
            <Button
              type="primary"
              css={editButtons}
              onClick={() => {
                dispatch({
                  type: SET_FIELD,
                  payload: [
                    { id: 'statusAlert', value: true },
                    { id: 'alertComponent', value: CaseStatus.AWAITINGINFO },
                  ],
                })
              }}
            >
              {'set to AwaitingInfo'}
            </Button>
          )}
          {(claimData?.status === CaseStatus.AWAITINGINFO ||
            claimData?.status === CaseStatus.INPROGRESS) && (
            <Button
              type="primary"
              css={editButtonsGroup}
              onClick={(e: any) => {
                formik.setFieldValue(
                  'resolutionAmount',
                  state.resAmount ? state.resAmount : undefined
                )
                onStatusResolveChange(e)
              }}
            >
              {'set to Resolved'}
            </Button>
          )}
          {claimTypesToSubmitToImporto.includes(claimType) &&
            claimData?.status !== CaseStatus.RESOLVED &&
            claimData?.status !== CaseStatus.CLOSED &&
            claimData?.status !== CaseStatus.REOPEN &&
            isImportoUserRole && (
              <Button
                type="primary"
                css={editButtonsGroup}
                onClick={() => {
                  fileClaim()
                }}
              >
                Submit Claim to Importo
              </Button>
            )}
          {claimData?.status === CaseStatus.INPROGRESS &&
            claimData.caseType === CaseType.INTERNALPROCESSING &&
            claimData.caseSubType === 'Transport Claims' &&
            claimData.workflow === 'TCLAIM' &&
            isTransportationTeamRole && (
              <Button
                type="primary"
                css={editButtonsGroup}
                onClick={() => {
                  fileClaim()
                }}
              >
                Submit Transport Claim
              </Button>
            )}
          {hasAnyAdminRole &&
            (claimData?.status === CaseStatus.RESOLVED ||
              claimData?.status === CaseStatus.CLOSED) && (
              <Button
                type="primary"
                css={editButtons}
                onClick={(e: any) => {
                  onReopenChange(e)
                }}
              >
                {'set to Reopen'}
              </Button>
            )}
          {updateLoading ||
            (submitLoading && <SpinnerLoad case={false} createCase={false} />)}
        </Grid.Item>
      </Grid.Container>
      <Grid.Container justify="space-between" className="hc-pa-none">
        <Grid.Item md={7} xs={12}>
          <Form>
            <EditVendorDetails vendorDetails={claimData} />
            {claimData?.claimDetails?.carrierVendorId !== null && (
              <EditCarrierVendorDetails carrierVendorDetails={claimData} />
            )}
            <EditDisputeDetails
              heading={EDIT_INTERNALPROCESS_HEADING}
              disputeDetails={claimData}
            />
            <EditDocumentDetails documentDetails={claimData} />
            <EditPaymentDetails paymentDetails={claimData} />
            <EditRecieptDetails receiptDetails={claimData} />
            <EditClaimReceipts tclaim={claimData} />
            {claimData?.claimDetails?.referenceDocumentNumber !== null && (
              <EditReferenceDetails referenceDetails={claimData} />
            )}
            <div id="myDivToFocus" ref={myDivToFocus}>
              <DownloadAttachments />
              {claimData?.status !== CaseStatus.CLOSED && (
                <BottomBorderDiv>
                  <AttachmentDetails
                    isVendor={userProfile?.isVendor}
                    reasonCode={claimData?.reasonCode}
                  />
                  <div className="hc-pa-normal hc-pb-dense hc-pt-none">
                    <Grid.Container direction="row-reverse">
                      <Grid.Item className="hc-pt-none">
                        <Button
                          size="dense"
                          type="primary"
                          onClick={prepareToUploadAttachments}
                          disabled={
                            attachmentDetails.uploadingAttachments ||
                            attachmentDetails.attachments?.length === 0 ||
                            attachmentDetails.attachmentTypeNotSet
                          }
                        >
                          {attachmentDetails.uploadingAttachments && (
                            <Spinner size="dense" />
                          )}
                          Upload New Attachments
                        </Button>
                        {attachmentDetails.uploadingAttachments && (
                          <Input.Label className="hc-fs-overline hc-blh-dense">
                            Uploading Files ...
                          </Input.Label>
                        )}
                      </Grid.Item>
                    </Grid.Container>
                  </div>
                </BottomBorderDiv>
              )}
            </div>
            <EditStatusModal
              headingText="Assign Case?"
              formik={formik}
              assignedUserValues={assignedUserValues}
              workflowValues={mappingValues?.getWorkflows}
              saveStatus={formik.handleSubmit}
            />
            <EditResolutionModal
              headingText="Claim Resolution Outcome/Settlement"
              editResolutionDetails={claimData}
              formik={formik}
              resolutionCodeValues={resolutionMappingValues}
              saveStatus={formik.handleSubmit}
              assignedUserValues={assignedUserValues}
            />
            <EditDescriptionModal
              headingText="Do you wish to update case brief explanation?"
              formik={formik}
              saveStatus={formik.handleSubmit}
            />
            <EditReferenceModal
              headingText="Add Claim / Deduction Document Details?"
              formik={formik}
              saveStatus={formik.handleSubmit}
            />
            <EditCarrierVendorModal
              headingText="Update Carrier Vendor Details?"
              formik={formik}
              handleSave={handleSave}
              handleCrVendorSearch={handleCrVendorSearch}
            />
            <EditDocumentCategoryModal
              formik={formik}
              docTypeValues={filteredDocTypeValues}
              docSubTypeValues={filteredDocSubTypeValues}
              saveStatus={formik.handleSubmit}
            />
            <ReopenModal
              headingText="Reopen the case"
              formik={formik}
              reopenDetails={claimData}
              saveStatus={formik.handleSubmit}
            />
          </Form>
        </Grid.Item>
        <Grid.Item md={5} xs={12} className="hc-pr-lg">
          <EditWorkflowDetails
            heading={WORKFLOW_SUMMARY_HEADING}
            workflowDetails={claimData}
            attachments={dataattachments?.getCaseAttachments?.length}
          />
          <CaseDateSummary caseDateDetails={claimData} />
          {(claimData?.status === CaseStatus.RESOLVED ||
            claimData?.status === CaseStatus.CLOSED) && (
            <EditResolutionDetails resolutionDetails={claimData} />
          )}
          {claimData?.isReopen !== null && claimData?.isReopen && (
            <ReopenDetails reopenDetails={claimData} />
          )}
          {duplicateCases && (
            <DuplicateCaseSummary
              heading="Duplicate Cases"
              duplicatesCases={duplicateCases}
            />
          )}
        </Grid.Item>
        <SupportingAlert />
      </Grid.Container>
      <div>
        <Grid.Item>
          <EditClaimItemLevelDetails tclaim={claimData} />
        </Grid.Item>
      </div>
      <div id="showActivity" ref={showActivity}>
        <Grid.Item className="hc-bg-white">
          <NotesTab
            caseDetails={claimData}
            formik={formik}
            saveComment={formik.handleSubmit}
            userProfile={userProfile}
          />
        </Grid.Item>
      </div>
    </Layout.Body>
  )
}
export default TMClaimView

import React, { useEffect, useState } from 'react'
import UserProfileContext, { Role } from './UserProfileContext'
import { useAuth } from '@praxis/component-auth'
import { useQuery, useLazyQuery } from '@apollo/client'
import { GET_USER_PROFILE, GetUserProfile, UserProfileVar } from './userProfle'
import { CreateProfile } from './CreateProfile'
import BaseLayout from '../BaseLayout'
import { Layout, Spinner } from '@enterprise-ui/canvas-ui-react'
import { GET_TEAMS, GetTeams } from './TeamQuery'
import { GetVendors, GET_VENDORS_BY_BP } from './BusinessPartnerQuery'
import { useEnv } from '@praxis/component-runtime-env'
import { EnvConfig } from '../../../config/appConfig'

const PROFILE_NOT_FOUND = 'UserProfile Not found'

interface Props {
  children: React.ReactNode
}

const UserProfileProvider: React.FC<Props> = (props) => {
  const { session, isAuthorized } = useAuth()
  const { auth } = useEnv() as EnvConfig
  const { data, error, loading } = useQuery<GetUserProfile, UserProfileVar>(
    GET_USER_PROFILE,
    {
      variables: { userId: session?.userInfo?.lanId ?? '' },
    }
  )

  function isAdminRole(role: Role | undefined): boolean {
    if (Role.ADMIN === role || Role.SADMIN === role) {
      return true
    }
    return false
  }

  function isImportoUserRole(): boolean {
    return isAuthorized(auth.importoUserRole)
  }

  function isTransportationTeamRole(): boolean {
    return isAuthorized(auth.transportationTeamRole)
  }

  function getUserRole(): Role | undefined {
    // @ts-ignore
    if (isAuthorized(auth.superAdminRole)) {
      return Role.SADMIN
    }

    // @ts-ignore
    if (isAuthorized(auth.adminRole)) {
      return Role.ADMIN
    }

    //@ts-ignore
    if (isAuthorized(auth.userRole)) {
      return Role.USER
    }

    //@ts-ignore
    if (isAuthorized(auth.vendorRole)) {
      return Role.VENDOR
    }
    return undefined
  }

  if (loading) {
    return (
      <Layout.Body>
        <BaseLayout centerAlign>
          <Spinner />
        </BaseLayout>
      </Layout.Body>
    )
  }

  if (error?.message === PROFILE_NOT_FOUND && getUserRole() !== undefined) {
    return <CreateProfile></CreateProfile>
  }

  if (error && error.message !== PROFILE_NOT_FOUND) {
    return (
      <Layout.Body>
        <h1>Something Went Wrong:</h1>
        <h4>Please try again later...</h4>
      </Layout.Body>
    )
  }

  if (getUserRole() === undefined) {
    return (
      <Layout.Body>
        <h1>Unauthorized :</h1>
        <h4>Please get the correct access to use this application</h4>
      </Layout.Body>
    )
  }

  return (
    <UserProfileContext.Provider
      value={{
        userProfile: data?.getUserProfile,
        userRole: getUserRole(),
        hasAnyAdminRole: isAdminRole(getUserRole()),
        isTransportationTeamRole: isTransportationTeamRole(),
        isImportoUserRole: isImportoUserRole(),
      }}
    >
      {props.children}
    </UserProfileContext.Provider>
  )
}

export function useProfile() {
  const context = React.useContext(UserProfileContext)
  if (context === undefined) {
    throw new Error(`useProfile must be used within a Use Profile Provider`)
  }
  return context
}

export default UserProfileProvider

export interface Values {
  id: String
  value: String
  label: String
}

export function useTeamsOrVendors(isVendor = false, userId: String): [any] {
  const [autoCompleteValues, setAutoCompleteValues] = useState<any>([])

  const [getTeams, teams] = useLazyQuery<GetTeams>(GET_TEAMS, {
    fetchPolicy: 'network-only',
  })
  const [getVendors, vendors] = useLazyQuery<
    GetVendors,
    { businessPartnerId: String }
  >(GET_VENDORS_BY_BP, {
    variables: { businessPartnerId: userId },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    if (isVendor) {
      getVendors()
    } else {
      getTeams()
    }
  }, [getTeams, getVendors, isVendor])

  useEffect(() => {
    if (teams.called && teams.data?.getTeams) {
      const values = teams.data?.getTeams?.map((team) => ({
        id: `${team.teamId}-${team.teamName}`,
        value: team.teamId,
        label: team.teamName,
      }))
      setAutoCompleteValues(values)
    }
  }, [teams])

  useEffect(() => {
    if (vendors.called && vendors.data?.getVendorsByBusinessPartner) {
      const values = vendors.data?.getVendorsByBusinessPartner?.map(
        (vendor) => ({
          id: `${vendor.gmsVendorNumber}-${vendor.vendorName}`,
          value: vendor.gmsVendorNumber,
          label: `${vendor.gmsVendorNumber} - ${vendor.vendorName}`,
          defaultVendorName: vendor.vendorName,
        })
      )

      setAutoCompleteValues(values)
    }
  }, [vendors])

  return autoCompleteValues
}
